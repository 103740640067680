import { Link, useLocation } from "react-router-dom";
import { Button, styled } from "@mui/material";
import React, { ReactNode } from "react";
import { theme } from "../customTheme";
import { useAuth } from "../pages/Login/AuthContext";

interface StyledLinkProps {
  active: boolean;
  children: ReactNode;
  to: string;
}

const StyledLink = styled(({ active, ...props }: StyledLinkProps) => (
  <Link {...props} />
))(({ active }) => ({
  textDecoration: "none",
  color: active ? theme.palette.secondary.main : "white",
}));

const StyledButton = styled(Button)({
  margin: "0 10px",
});

interface NavigationButtonProps {
  to: string;
  children: ReactNode;
}

export default function NavigationButton({
  to,
  children,
}: NavigationButtonProps) {
  const { isAdmin, currentUser } = useAuth();
  const location = useLocation();
  const active = location.pathname === to;
  if (!currentUser || !isAdmin) {
    return <div></div>;
  }
  return (
    <StyledButton>
      <StyledLink active={active} to={to}>
        {children}
      </StyledLink>
    </StyledButton>
  );
}
