import { Shop } from "../model/Shop";
import { ShopDataGrid } from "../components/ShopDataGrid";
import { getShops } from "../service/FirebaseService";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ShopList() {
  const [shops, setShops] = useState<Shop[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getShops().then((shops) => setShops([...shops]));
  }, []);
  const handleClick = () => {
    navigate(`/create`);
  };
  return (
    <>
      <Box sx={{ textAlign: "right", m: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          className="ce"
        >
          Créer un magasin
        </Button>
      </Box>
      <ShopDataGrid shops={shops} />
    </>
  );
}
