import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const { currentUser, isAdmin } = useAuth();
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  if (!isAdmin) {
    return <Navigate to="/not-authorized" />; // Redirigez vers une page "Non autorisé"
  }
  return children;
}
