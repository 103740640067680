export interface BusinessHours {
  startingTime?: string;
  closingTime?: string;
}

export interface Day {
  open: boolean;
  businessHours: BusinessHours[];
}

export interface ShopBusinessDays {
  monday: Day;
  tuesday: Day;
  wednesday: Day;
  thursday: Day;
  friday: Day;
  saturday: Day;
  sunday: Day;
}

export enum Days {
  monday = "Lundi",
  tuesday = "Mardi",
  wednesday = "Mercredi",
  thursday = "Jeudi",
  friday = "Vendredi",
  saturday = "Samedi",
  sunday = "Dimanche",
}
