import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { TextField } from "@mui/material";
import { Address } from "../../model/Address";

type AddressProps = {
  address: Address;
  setAddress: (shop: Address) => void;
  errors: any;
};
const getFullAddress = (address: Address) => {
  if (
    address.street !== "" ||
    address.zipCode !== "" ||
    address.city !== "" ||
    address.country !== ""
  ) {
    return `${address.street} ${address.city} ${address.zipCode} ${address.country}`.trim();
  }
  return "";
};

export function AddressForm({ address, setAddress, errors }: AddressProps) {
  const [inputValue, setInputValue] = useState<string>(
    address !== undefined ? getFullAddress(address) : "",
  );
  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocomplete.addListener("place_changed", () =>
      onPlaceChanged(autocomplete),
    );
  };
  const onPlaceChanged = (autocomplete: google.maps.places.Autocomplete) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      if (addressComponents) {
        const city =
          addressComponents.find((component) =>
            component.types.includes("locality"),
          )?.long_name || "";
        const zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code"),
          )?.long_name || "";
        const street = place.name;
        const country =
          addressComponents.find((component) =>
            component.types.includes("country"),
          )?.long_name || "";
        const latitude = place.geometry?.location?.lat() || undefined;
        const longitude = place.geometry?.location?.lng() || undefined;
        const newAddress = {
          city,
          zipCode,
          street,
          country,
          longitude,
          latitude,
        } as Address;
        setAddress(newAddress);
        setInputValue(getFullAddress(newAddress));
      }
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setAddress({
        country: "",
        street: "",
        city: "",
        zipCode: "",
        latitude: 0,
        longitude: 0,
      });
    }
    setInputValue(e.target.value);
  };

  return (
    <Autocomplete onLoad={onLoad}>
      <TextField
        label="Adresse"
        name="adress"
        value={inputValue}
        onChange={handleInputChange}
        fullWidth
        error={errors.address}
      />
    </Autocomplete>
  );
}
