import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import { Shop } from "../../model/Shop";

type GeneralInfoFormProps = {
  shop: Shop;
  setShop: (shop: Shop) => void;
  errors: any;
  validateField: (name: string, value: string) => void;
};

export function ManagerInfoForm({
  shop,
  setShop,
  errors,
  validateField,
}: GeneralInfoFormProps) {
  const handleManagerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name, value);
    setShop({ ...shop, manager: { ...shop.manager, [name]: value } });
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom mb={2}>
          Information du commerçant
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Prénom du commerçant"
              name="firstName"
              value={shop.manager.firstName}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.firstName}
              helperText={errors.firstName ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nom du commerçant"
              name="lastName"
              value={shop.manager.lastName}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.lastName}
              helperText={errors.lastName ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Téléphone administratif"
              name="managerPhone"
              value={shop.manager.managerPhone}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.managerPhone}
              helperText={errors.managerPhone ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-mail administratif"
              name="managerEmail"
              value={shop.manager.managerEmail}
              onChange={handleManagerChange}
              fullWidth
              required
              error={errors.managerEmail}
              helperText={errors.managerEmail ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
