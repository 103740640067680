import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/x-data-grid/locales";
import { frFR as coreFrFR } from "@mui/material/locale";

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#006177",
      },
      secondary: {
        main: "#20d9ff",
      },
    },
    typography: {
      fontFamily: '"Comic Sans MS", cursive, sans-serif',
    },
    spacing: 8,
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  },
  frFR,
  coreFrFR,
);
