import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Shop,
  ShopStatus,
  ShopStatusColors,
  ShopStatusValue,
} from "../model/Shop";
import "./ShopDataGrid.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Address } from "../model/Address";

const getFullAddress = (address: Address) => {
  if (
    address.street !== "" ||
    address.zipCode !== "" ||
    address.city !== "" ||
    address.country !== ""
  ) {
    return `${address.street} ${address.city} ${address.zipCode} ${address.country}`.trim();
  }
  return "";
};
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  {
    field: "fullName",
    headerName: "Commerçant",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { firstName, lastName } = params.row.manager;
      return <span>{`${firstName} ${lastName}`}</span>;
    },
  },
  { field: "name", headerName: "Nom", flex: 1 },
  {
    field: "phone",
    headerName: "Téléphone",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { managerPhone } = params.row.manager;
      return <span>{`${managerPhone}`}</span>;
    },
  },
  {
    field: "address",
    headerName: "Adresse",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const address = params.row.address;
      return <span>{getFullAddress(address)}</span>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => (
      <span style={{ color: ShopStatusColors[params.value as ShopStatus] }}>
        {ShopStatusValue[params.value as ShopStatus]}
      </span>
    ),
  },
];

export function ShopDataGrid({ shops }: { shops: Shop[] }) {
  const rows = shops.map((shop) => ({
    id: shop.id,
    manager: shop.manager,
    name: shop.name,
    phone: shop.phone,
    address: shop.address,
    status: shop.status,
  }));
  const navigate = useNavigate();

  const handleRowClick = (param: any) => {
    navigate(`/edit/${param.id}`);
  };

  return (
    <Grid style={{ height: "calc(100vh - 64px)", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "primary.main",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        onRowClick={handleRowClick}
      />
    </Grid>
  );
}
