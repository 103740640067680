import { Address } from "./Address";
import { ShopBusinessDays } from "./BusinessHours";
import { theme } from "../customTheme";
import { User } from "./User";

export interface Shop {
  id: string;
  name: string;
  phone?: string;
  email?: string;
  manager: User;
  address: Address;
  businessDays: ShopBusinessDays;
  logo?: string;
  status: keyof typeof ShopStatus;
  siret: string;
  createdAt?: Date;
  logoFile?: File;
}

export enum ShopStatus {
  ACTIVE = "ACTIVE",
  DISABLE = "DISABLE",
  PENDING = "PENDING",
}

export const ShopStatusValue = {
  [ShopStatus.ACTIVE]: "Activé",
  [ShopStatus.DISABLE]: "Désactivé",
  [ShopStatus.PENDING]: "Demande en cours",
};
export const ShopStatusColors = {
  [ShopStatus.ACTIVE]: theme.palette.success.main,
  [ShopStatus.DISABLE]: theme.palette.error.main,
  [ShopStatus.PENDING]: theme.palette.warning.main,
};
