import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebaseConfig"; // Assurez-vous que le chemin d'importation est correct
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";

export function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        "Un e-mail de réinitialisation a été envoyé. Vérifiez votre boîte de réception.",
      );
      setError("");
    } catch (error) {
      setError(
        "Erreur lors de l'envoi de l'e-mail de réinitialisation. Vérifiez votre adresse e-mail.",
      );
      setMessage("");
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h4" gutterBottom>
          Mot de passe oublié ?
        </Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
          fullWidth
        >
          Réinitialiser le mot de passe
        </Button>
        {message && (
          <Typography color="primary" style={{ marginTop: "16px" }}>
            {message}
          </Typography>
        )}
        {error && (
          <Typography color="error" style={{ marginTop: "16px" }}>
            {error}
          </Typography>
        )}
        <Button
          onClick={() => navigate("/login")}
          style={{ marginTop: "16px", color: "#1976d2" }}
        >
          Retour à la connexion
        </Button>
      </Grid>
    </Grid>
  );
}
