import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ShopEditor } from "./ShopEditor";
import { ShopDeals } from "../ShopDeals/ShopDeals";
import { TabPanel } from "../../components/TabPanel";
import { ScheduleForm } from "./ScheduleForm";
import { useShopEditor } from "../../hook/useShopEditor";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export function ShopView() {
  const [value, setValue] = useState(0);
  const { id } = useParams<{ id: string }>();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    shop,
    setShop,
    handleSave,
    validateField,
    showSnackbar,
    errors,
    setShowSnackbar,
    shopIsValid,
    deals,
    fetchNewDeals,
  } = useShopEditor(id);
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
      >
        <Tab label="Information du magasin" {...a11yProps(0)} />
        <Tab label="Les horaires" {...a11yProps(1)} />
        {id !== undefined && <Tab label="Les promotions" {...a11yProps(2)} />}
      </Tabs>
      <Container sx={{ my: 5 }} maxWidth={"xl"}>
        {shop?.name && (
          <Typography
            variant="h5"
            component="div"
            marginBottom={2}
            color="primary"
          >
            Nom du magasin: {shop.name}
          </Typography>
        )}
        <TabPanel value={value} index={0}>
          <ShopEditor
            setShop={setShop}
            shop={shop!}
            errors={errors}
            validateField={validateField}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ScheduleForm shop={shop!} setShop={setShop} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ShopDeals
            deals={deals}
            fetchDeals={fetchNewDeals}
            shopId={shop?.id!}
          />
        </TabPanel>
        {showSnackbar && (
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setShowSnackbar(false)}
              severity={shopIsValid(errors) ? "success" : "error"}
              variant="filled"
            >
              {shopIsValid(errors)
                ? "Enregistrement des données du magasin."
                : "Veuillez remplir tous les champs obligatoires."}
            </Alert>
          </Snackbar>
        )}
        {value !== 2 && (
          <Grid
            container
            justifyContent="flex-end"
            sx={{
              position: "fixed",
              bottom: 50,
              left: -50,
              width: "100%",
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSave}>
              Enregistrer
            </Button>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
