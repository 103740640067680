import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Shop,
  ShopStatus,
  ShopStatusColors,
  ShopStatusValue,
} from "../../model/Shop";
import { User } from "../../model/User";
import { AddressForm } from "./AddressForm";
import { Address } from "../../model/Address";
import React from "react";

type GeneralInfoFormProps = {
  shop: Shop;
  setShop: React.Dispatch<React.SetStateAction<Shop | undefined>>;
  errors: any;
  validateField: (name: string, value: string) => void;
};

export function ShopInfoForm({
  shop,
  setShop,
  errors,
  validateField,
}: GeneralInfoFormProps) {
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name !== "phone" && name !== "email") {
      validateField(name, value);
    }
    setShop({ ...shop, [name]: value });
  };
  const handleAddressChange = (address: Address) => {
    setShop((shop) => {
      return { ...shop, address: address } as Shop;
    });
  };
  const copyAdminData = (adminField: string, field: string) => {
    const newValue = shop.manager[adminField as keyof User];
    setShop({
      ...shop,
      [field]: newValue,
    });
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom mb={2}>
          Information du magasin
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              margin="none"
              required
              error={errors.status}
            >
              <InputLabel id="status-label">Statut</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={shop.status}
                onChange={handleInputChange}
                label="Statut"
                style={{ color: ShopStatusColors[shop.status as ShopStatus] }}
                variant="outlined"
              >
                {Object.entries(ShopStatus).map(([statusKey, statusValue]) => (
                  <MenuItem
                    key={statusKey}
                    value={statusValue}
                    style={{ color: ShopStatusColors[statusValue] }}
                  >
                    {ShopStatusValue[statusValue]}
                  </MenuItem>
                ))}
              </Select>
              {errors.status && (
                <FormHelperText>Ce champ est obligatoire</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nom du magasin"
              name="name"
              value={shop.name}
              onChange={handleInputChange}
              fullWidth
              required
              error={errors.name}
              helperText={errors.name ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Siret"
              name="siret"
              value={shop.siret}
              onChange={handleInputChange}
              fullWidth
              required
              error={errors.siret}
              helperText={errors.siret ? "Ce champ est obligatoire" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Téléphone"
              name="phone"
              value={shop.phone ?? ""}
              onChange={handleInputChange}
              fullWidth
            />
            <Button
              onClick={() => copyAdminData("managerPhone", "phone")}
              variant="outlined"
              style={{ marginTop: 8 }}
            >
              Copier le téléphone administratif
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-mail"
              name="email"
              value={shop.email ?? ""}
              onChange={handleInputChange}
              fullWidth
            />
            <Button
              onClick={() => copyAdminData("managerEmail", "email")}
              variant="outlined"
              style={{ marginTop: 8 }}
            >
              Copier l'email administratif
            </Button>
          </Grid>
          <Grid item xs={12}>
            <AddressForm
              address={shop.address}
              setAddress={handleAddressChange}
              errors={errors}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
