import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  BusinessHours,
  Days,
  ShopBusinessDays,
} from "../../model/BusinessHours";
import { Shop } from "../../model/Shop";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

type ScheduleFormProps = {
  shop: Shop;
  setShop: (shop: Shop) => void;
};

export function ScheduleForm({ shop, setShop }: ScheduleFormProps) {
  const handleDayChange = (
    day: keyof ShopBusinessDays,
    field: string,
    value: boolean | BusinessHours[],
  ) => {
    const newDays = {
      ...shop.businessDays,
      [day]: {
        ...shop.businessDays[day],
        [field]: value,
      },
    };
    setShop({ ...shop, businessDays: newDays });
  };

  const handleTimeChange = (
    day: keyof ShopBusinessDays,
    index: number,
    field: string,
    value: string,
  ) => {
    const newOpenHours = shop.businessDays[day].businessHours.map((hour, i) => {
      if (i === index) {
        return {
          ...hour,
          [field]: value,
        };
      }
      return hour;
    });
    handleDayChange(day, "businessHours", newOpenHours);
  };

  const handleAddTimeSlot = (day: keyof ShopBusinessDays) => {
    const newOpenHours = [
      ...shop.businessDays[day].businessHours,
      { startingTime: "", closingTime: "" },
    ];
    handleDayChange(day, "businessHours", newOpenHours);
  };

  const handleRemoveTimeSlot = (day: keyof ShopBusinessDays, index: number) => {
    const newOpenHours = shop.businessDays[day].businessHours.filter(
      (_, i) => i !== index,
    );
    handleDayChange(day, "businessHours", newOpenHours);
  };
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Horaires d'ouverture
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(Days).map((day) => (
          <Grid item xs={12} md={6} lg={4} key={day}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {Days[day as keyof ShopBusinessDays]}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        shop.businessDays[day as keyof ShopBusinessDays].open
                      }
                      onChange={(e) =>
                        handleDayChange(
                          day as keyof ShopBusinessDays,
                          "open",
                          e.target.checked,
                        )
                      }
                    />
                  }
                  label="Ouvert"
                />
                {shop.businessDays[day as keyof ShopBusinessDays] && (
                  <>
                    {shop.businessDays[
                      day as keyof ShopBusinessDays
                    ].businessHours.map(
                      (hours: BusinessHours, index: number) => (
                        <Grid
                          container
                          spacing={2}
                          key={index}
                          alignItems="center"
                          mb={1}
                        >
                          <Grid item xs={5}>
                            <TextField
                              label="Heure d'ouverture"
                              type="time"
                              value={hours.startingTime || ""}
                              onChange={(e) =>
                                handleTimeChange(
                                  day as keyof ShopBusinessDays,
                                  index,
                                  "startingTime",
                                  e.target.value,
                                )
                              }
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ step: 300 }} // 5 min
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              label="Heure de fermeture"
                              type="time"
                              value={hours.closingTime || ""}
                              onChange={(e) =>
                                handleTimeChange(
                                  day as keyof ShopBusinessDays,
                                  index,
                                  "closingTime",
                                  e.target.value,
                                )
                              }
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ step: 300 }} // 5 min
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveTimeSlot(
                                  day as keyof ShopBusinessDays,
                                  index,
                                )
                              }
                            >
                              <DeleteIcon style={{ fontSize: "1.5rem" }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ),
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        handleAddTimeSlot(day as keyof ShopBusinessDays)
                      }
                      fullWidth
                      style={{ marginTop: "10px" }}
                    >
                      Ajouter un créneau
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
