import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  AppBar,
  Button,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { theme } from "./customTheme";
import NavigationButton from "./components/NavigationButton";
import ShopList from "./pages/ShopList";
import { ShopView } from "./pages/ShopEditor/ShopView";
import { Libraries, LoadScript } from "@react-google-maps/api";
import { LoginPage } from "./pages/Login/LoginPage";
import { AuthProvider, useAuth } from "./pages/Login/AuthContext";
import { PrivateRoute } from "./pages/Login/PrivateRoute";
import { NotAuthorized } from "./pages/Login/NotAuthorized";
import { getAuth, signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import { ForgotPasswordPage } from "./pages/Login/ForgotPasswordPage";

const libraries = ["places"] as Libraries;

function App() {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
  return (
    <LoadScript
      googleMapsApiKey={API_KEY}
      libraries={libraries}
      id="script-loader"
    >
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <AppBar position="static">
              <Toolbar
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <NavigationButton to="/">Draveil</NavigationButton>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" sx={{ mx: 2 }}>
                    Mes commercants Ma ville
                  </Typography>
                  <LogOutButton />
                </div>
              </Toolbar>
            </AppBar>

            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <ShopList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit/:id"
                element={
                  <PrivateRoute>
                    <ShopView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create"
                element={
                  <PrivateRoute>
                    <ShopView />
                  </PrivateRoute>
                }
              />
              <Route path="/not-authorized" element={<NotAuthorized />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </LoadScript>
  );
}

function LogOutButton() {
  const navigate = useNavigate();
  const auth = getAuth(); // Récupérer l'authentification Firebase
  const { currentUser } = useAuth();
  const handleLogoutAndReturnHome = () => {
    signOut(auth)
      .then(() => {
        navigate("/"); // Rediriger vers la page d'accueil après la déconnexion
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion", error);
      });
  };
  if (!currentUser) {
    return <></>;
  }
  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleLogoutAndReturnHome}
      >
        <LogoutIcon />
      </Button>
    </>
  );
}

export default App;
