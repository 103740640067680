import { useDropzone } from "react-dropzone";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

type LogoUploaderProps = {
  logo: string;
  onDrop: (acceptedFiles: File[]) => void;
  title: string;
};

export function LogoUploader({ logo, onDrop, title }: LogoUploaderProps) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box
          {...getRootProps({ className: "dropzone" })}
          sx={{
            textAlign: "center",
            p: 2,
            border: "2px dashed #cccccc",
            borderRadius: 2,
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body2" color="textSecondary">
            Faites glisser une image ici, ou cliquez pour sélectionner une image
          </Typography>
        </Box>
        {logo && (
          <Grid mt={2}>
            <CardMedia component="img" image={logo} alt={title} />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
