import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth"; // Importation des fonctionnalités Firebase

export function NotAuthorized() {
  const navigate = useNavigate();
  const auth = getAuth(); // Récupérer l'authentification Firebase

  const handleLogoutAndReturnHome = () => {
    signOut(auth)
      .then(() => {
        navigate("/"); // Rediriger vers la page d'accueil après la déconnexion
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion", error);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", marginTop: 8 }}>
      <ErrorOutlineIcon sx={{ fontSize: 80, color: "error.main", mb: 2 }} />
      <Typography variant="h4" gutterBottom color="error">
        Accès refusé
      </Typography>
      <Typography variant="body1" gutterBottom>
        Vous n'avez pas les autorisations nécessaires pour accéder à cette page.
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
        Si vous pensez qu'il s'agit d'une erreur, contactez un administrateur.
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogoutAndReturnHome}
        >
          Se déconnecter et retourner à l'accueil
        </Button>
      </Box>
    </Container>
  );
}
